import axios from "axios";
import { url } from "../../config/constant";

const instance = axios.create({
  baseURL: url.BASE_URL,
});

instance.interceptors.request.use(
  async (config) => {
    if (
      !config.url?.includes("auth") ||
      config.url?.includes("auth/profile")
    ) {
      const token = await localStorage.getItem("token");

      if (token) {
        config.headers!.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },

  (err) => {
    return Promise.reject(err);
  }
);

export default instance;
