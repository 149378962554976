import { useEffect } from "react";
import { useApi } from "../../../provider/API/call-services";
const AdminTransaction = () => {
  const { API } = useApi();

//   useEffect(() => {
//     API.getStats()
//   },[])
    return (
      <>
        <div className="text-white pt-20  font-fontMont gap-10">
          <h2 className="text-2xl font-fontMont font-semibold ">
            Transactions History
          </h2>
        </div>
      </>
    );
}
export default AdminTransaction
