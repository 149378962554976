import { Suspense, lazy, useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import SpinnerWrapper from "./components/SpinnerWrapper";
import { Toaster } from "react-hot-toast";
import AppLayout from "./components/Layout/AppLayout";
import NotifierComponents from "react-pop-notifier";
import AdminAppLayout from "./components/Layout/AdminAppLayout";
import { PopUps } from "./components/Popups";
import { ApiProvider } from "./provider/API/call-services";
import { useAppInfo } from "./provider/contexts/appInfoContext";
import ProtectedRoute from "./components/ProtectedRoutes";
import Profile from "./app/profile";
import AdminProtectedRoute from "./components/AdminProtectedRoute";
import AdminTransaction from "./app/Admin/AdminTransaction";

const LandingPage = lazy(() => import("./pages/LandingPage"));
const Authentication = lazy(() => import("./app/authentication"));
const Register = lazy(() => import("./app/authentication/Register"));
const Login = lazy(() => import("./app/authentication/Login"));
const ContactSupport = lazy(() => import("./pages/ContactSupport"));
const PageNotFound = lazy(() => import("./pages/PageNotFound"));
const Faqs = lazy(() => import("./pages/Faqs"));
const AboutUs = lazy(() => import("./pages/AboutUs"));
const DollarCard = lazy(() => import("./pages/DollarCard"));
const ForeignAccount = lazy(() => import("./pages/ForeignAccount"));
const DigitalBanking = lazy(() => import("./pages/DigitalBanking"));
const Airtime = lazy(() => import("./pages/Airtime"));
const DataSubscription = lazy(() => import("./pages/DataSubscription"));
const BillPayment = lazy(() => import("./pages/BillPayment"));
const CableSubscription = lazy(() => import("./pages/CableSubscription"));
const Dashboard = lazy(() => import("./app/dashboard"));
const FundAccount = lazy(() => import("./app/fund-account"));
const AirtimeVtu = lazy(() => import("./app/airtime-vtu"));
const DataSubscriptionDashboard = lazy(() => import("./app/data-subscription"));
const Utilities = lazy(() => import("./app/utilities"));
const CableSubscriptionDashboard = lazy(
  () => import("./app/cable-subscription")
);
const TransactionHistory = lazy(() => import("./app/transaction-history"));
const AdminLogin = lazy(() => import("./app/Admin/AdminLogin"));
const AdminDashboard = lazy(() => import("./app/Admin/AdminDashboard"));
const AdminManageUsers = lazy(() => import("./app/Admin/AdminManageUsers"));
const AdminRevenue = lazy(() => import("./app/Admin/AdminRevenue"));
const AdminAudit = lazy(() => import("./app/Admin/AdminAudit"));
const AdminAirtime = lazy(() => import("./app/Admin/AdminAirtime"));
const AdminAirtimeTransactions = lazy(
  () => import("./app/Admin/AdminAirtimeTransaction")
);
const AdminTv = lazy(() => import("./app/Admin/AdminTv"));
const AdminTvPlans = lazy(() => import('./app/Admin/AdminTvPlans'))
const AdminElectricity = lazy(() => import('./app/Admin/AdminElectricity'))
const AdminData = lazy (() => import('./app/Admin/AdminData'));
const AdminDataTransactions = lazy(() => import('./app/Admin/AdminDataTransactions'));
const AdminTvTransactions = lazy(() => import('./app/Admin/AdminTvTransactions'));
const AdminElectricityTransactions = lazy(() => import('./app/Admin/AdminElecticityTransactions'));
const  AdminSendNotification = lazy(() => import('./app/Admin/AdminNotify/Send'));
const  AdminCircularNotification = lazy(() => import('./app/Admin/AdminNotify/Circular'));
const UnilorinTransit = lazy(() => import('./pages/UnilorinTransit'));
const { Notifier } = NotifierComponents;

function App() {
  const { recoverStatesData } = useAppInfo();

  useEffect(() => {
    recoverStatesData();
  }, []);

  return (
    <>
      <ApiProvider>
        <PopUps />
        <BrowserRouter>
          <Suspense fallback={<SpinnerWrapper />}>
            <Routes>
              <Route index element={<LandingPage />} />
              <Route element={<Authentication />} path="auth">
                <Route index element={<Navigate to="login" replace />} />
                <Route path="register" element={<Register />} />
                <Route path="login" element={<Login />} />
              </Route>
              <Route path="/admin/login" element={<AdminLogin />} />
              <Route
                element={
                  <AdminProtectedRoute>
                    <AdminAppLayout />
                  </AdminProtectedRoute>
                }
              >
                <Route
                  index
                  element={<Navigate replace to="/admin/dashboard" />}
                />
                <Route path="/admin/dashboard" element={<AdminDashboard />} />
                <Route
                  path="/admin/manage-users"
                  element={<AdminManageUsers />}
                />
                <Route
                  path="/admin/transactions"
                  element={<AdminTransaction />}
                />
                <Route path="/admin/revenue" element={<AdminRevenue />} />
                <Route path="/admin/audit" element={<AdminAudit />} />
                <Route
                  path="/admin/manage-airtime"
                  element={<AdminAirtime />}
                />
                <Route
                  path="/admin/airtime-transactions"
                  element={<AdminAirtimeTransactions />}
                />
                <Route path="/admin/manage-tv" element={<AdminTv />} />
                <Route
                  path="/admin/manage-electricity"
                  element={<AdminElectricity />}
                />
                <Route path="/admin/manage-tv/:id" element={<AdminTvPlans />} />
                <Route
                  path="/admin/manage-databundle/:id"
                  element={<AdminData />}
                />
                <Route
                  path="/admin/databundle-transactions"
                  element={<AdminDataTransactions />}
                />
                <Route
                  path="/admin/tv-transactions"
                  element={<AdminTvTransactions />}
                />
                <Route
                  path="/admin/electricity-transactions"
                  element={<AdminElectricityTransactions />}
                />
                <Route
                  path="/admin/notify/all"
                  element={<AdminSendNotification />}
                />
                <Route
                  path="/admin/notify/circular"
                  element={<AdminCircularNotification />}
                />
              </Route>
              <Route
                element={
                  <ProtectedRoute>
                    <AppLayout />
                  </ProtectedRoute>
                }
                path="app"
              >
                <Route index element={<Navigate to="dashboard" replace />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="fund-account" element={<FundAccount />} />
                <Route path="airtime-vtu" element={<AirtimeVtu />} />
                <Route
                  path="data-subscriptions"
                  element={<DataSubscriptionDashboard />}
                />
                <Route path="profile" element={<Profile />} />
                <Route path="utilities" element={<Utilities />} />
                <Route
                  path="cable-subscriptions"
                  element={<CableSubscriptionDashboard />}
                />
                <Route
                  path="transaction-history"
                  element={<TransactionHistory />}
                />
              </Route>
              <Route path="contact-support" element={<ContactSupport />} />
              <Route path="unilorin-shuttle" element={<UnilorinTransit/>} />
              <Route path="faqs" element={<Faqs />} />
              <Route path="about-us" element={<AboutUs />} />
              <Route path="dollar-card" element={<DollarCard />} />
              <Route path="foreign-account" element={<ForeignAccount />} />
              <Route path="digital-banking" element={<DigitalBanking />} />
              <Route path="airtime-VTU" element={<Airtime />} />
              <Route path="bill-payment" element={<BillPayment />} />
              <Route
                path="cable-subscription"
                element={<CableSubscription />}
              />
              <Route path="data-subscription" element={<DataSubscription />} />

              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
        <Notifier />
      </ApiProvider>

      <Toaster
        position="top-center"
        gutter={12}
        containerStyle={{ margin: "8px" }}
        toastOptions={{
          success: { duration: 3000 },
          error: { duration: 5000 },
          style: {
            fontSize: "16px",
            width: "500px",
            padding: "16px 24px",
            fontFamily: "Montserrat",
          },
        }}
      />
    </>
  );
}

export default App;
