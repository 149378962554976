export function getPoint(point: string){
    const hasPoint = point.includes(".") ? true : false
    return hasPoint
}

const splitCamelCase = (text: string, isTitleCase?: boolean) => {
    let result = text.replace(/([a-z])([A-Z])/g, '$1 $2')
    result = isTitleCase ? result[0].toUpperCase() + result.substring(1) : result 
    return result
}

export const getFullYear = () => {
    return new Date().getFullYear()
}
export const catchErrMsg = (err: any) => {
  let message = err?.response?.data?.msg;

  if (err.response?.status === 500) message = "Something went wrong (Server)";
  return message;
};

export function formatDateToMonthDayYear(date: Date | string): string {
  const d = new Date(date);

  // Check if the date is valid
  if (isNaN(d.getTime())) {
    throw new Error("Invalid date");
  }

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const month = months[d.getMonth()];
  const day = d.getDate();
  const year = d.getFullYear();

  return `${month} ${day}, ${year}`;
}

export const generateTimeOptions = () => {
  return Array.from({ length: 24 }, (_, i) => {
    const hour = i === 0 ? 12 : i > 12 ? i - 12 : i;
    const ampm = i < 12 ? "AM" : "PM";
    const value = `${i.toString().padStart(2, "0")}:00`;
    const label = `${hour.toString().padStart(2, "0")}:00 ${ampm}`;
    return [label];
  });
};





export default {splitCamelCase}