import { ReactNode } from "react";
import { Link } from "react-router-dom";

type ButtonProps = {
  className?: String;
  children: ReactNode | string;
  color?: string;
  bgColor?: string;
  size?: string;
  hoverBgColor?: string;
  onClick?: (prop: any) => void;
  as: string;
  to?: string;
  borderRadius?: string;
};
export const Button = ({
  className,
  children,
  color,
  bgColor,
  size = "sm",
  hoverBgColor,
  onClick,
  as,
  to = "/",
  borderRadius,
}: ButtonProps) => {
  if (as === "link")
    return (
      <Link
        to={to}
        className="px-7 py-4 bg-primaryPurple  duration-100 hover:bg-[rgb(130,0,111,.8)] text-white rounded-md"
      >
        {children}
      </Link>
    );
  return (
    <button
      onClick={onClick}
      className={` ${
        size === "sm" ? "px-5" : size === "md" ? "px-6" : "px-7"
      } px-5 py-4 font-medium ${
        borderRadius ? borderRadius : "rounded-lg"
      } flex gap-3 items-center justify-center font-fontMont ${
        bgColor ? bgColor : "bg-primaryPurple"
      } ${color ? color : "text-white"} ${className} duration-100 ${
        hoverBgColor ? hoverBgColor : "hover:bg-[rgb(130,0,111,.8)]"
      } `}
    >
      {children}
    </button>
  );
};
