import { BsPatchCheckFill } from "react-icons/bs";
import Avatar from "../../assets/img/user.png";
import { IoMdEye, IoMdEyeOff, IoMdCopy } from "react-icons/io";

import { useEffect, useState } from "react";
import { Button } from "../../components/Button";
import { useApi } from "../../provider/API/call-services";
import { useUser } from "../../provider/contexts/userContext";
import { PinModal } from "../../components/PinModal";
import { useVisibility } from "../../provider/contexts/visibilityContext";
const Profile = () => {
  const [showBalance, setShowBalance] = useState(false);
  const {
    user: { profile },
  } = useUser();
  const { setModal } = useVisibility();
  const { API } = useApi();
 
  async function handleReset() {
    const response = await API.resetPin();
    if (response) {
      setModal({ key: "pinModal", value: true });
    }
  }
  return (
    <div>
      <PinModal />
      <div className="md:pt-20 pt-5 font-fontMont text-white">
        <h2 className="font-fontMont text-2xl font-semibold ">Profile</h2>

        <div className="flex gap-5 items-center mt-10">
          <img src={Avatar} alt="avatar" className="w-24 h-24" />
          <div className="flex gap-4 flex-col">
            <h3 className="text-2xl font-medium">
              {profile?.first_name} {profile?.last_name}
            </h3>
            <div className="flex justify-center items-center h-6 rounded-full bg-[#E7DCE8] gap-3 w-36">
              {profile?.can_transact ? (
                <p className="text-black text-base font-fontMont">Verified</p>
              ) : (
                <p className="text-colorGold text-base font-fontMont">
                  Not Verified
                </p>
              )}

              <BsPatchCheckFill color="#82006F" size={15} />
            </div>
          </div>
        </div>

        <div className="my-10">
          <Button as="btn" onClick={handleReset}>
            Reset Pin
          </Button>
        </div>

        <div className="mt-10 space-y-10">
          <h2 className="font-fontMont text-2xl font-semibold">
            Personal Details
          </h2>

          <div className="border border-slate-800 rounded-md sm:p-10 p-5 grid sm:grid-cols-2 grid-cols-1 lg:w-[50rem] w-full gap-10">
            <div className="flex flex-col gap-2">
              <label htmlFor="" className="font-semibold text-xl">
                Full name
              </label>
              <span className="font-light text-base">
                {profile?.first_name} {profile?.last_name}
              </span>
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="" className="font-semibold text-xl">
                Phone number
              </label>
              <span className="font-light text-base">{profile?.phone}</span>
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="" className="font-semibold text-xl">
                Email address
              </label>
              <span className="font-light text-base">{profile?.email}</span>
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="" className="font-semibold text-xl">
                Date of birth
              </label>
              <span className="font-light text-base">Aug 24, 2024</span>
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="" className="font-semibold text-xl">
                Password
              </label>
              <div className="border-[1px] rounded-lg p-3.5 flex justify-between gap-4 items-center border-borderColor">
                <input
                  type={showBalance ? "text" : "password"}
                  className="border-none focus:outline-0 bg-transparent placeholder:font-groteska-regular w-full placeholder:font-base text-white"
                />
                {showBalance ? (
                  <IoMdEye
                    onClick={() => setShowBalance(false)}
                    color="#fff"
                    className="cursor-pointer"
                    size={20}
                  />
                ) : (
                  <IoMdEyeOff
                    onClick={() => setShowBalance(true)}
                    color="#fff"
                    size={20}
                    className="cursor-pointer"
                  />
                )}
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="" className="font-semibold text-xl">
                Transaction Pin
              </label>
              <div className="border-[1px] rounded-lg p-3.5 flex justify-between gap-4 items-center border-borderColor">
                <input
                  type={showBalance ? "text" : "password"}
                  className="border-none focus:outline-0 bg-transparent placeholder:font-groteska-regular w-full placeholder:font-base text-white"
                />
                {showBalance ? (
                  <IoMdEye
                    onClick={() => setShowBalance(false)}
                    color="#fff"
                    className="cursor-pointer"
                    size={20}
                  />
                ) : (
                  <IoMdEyeOff
                    onClick={() => setShowBalance(true)}
                    color="#fff"
                    size={20}
                    className="cursor-pointer"
                  />
                )}
              </div>
            </div>
            <div className="">
              <Button as="btn">Update Profile</Button>
            </div>
          </div>
        </div>

        <div className="mt-10 space-y-10">
          <h2 className="font-fontMont text-2xl font-semibold">
            Personal Details
          </h2>
          <div className="border border-slate-800 rounded-md sm:p-10 p-5 grid sm:grid-cols-2 grid-cols-1 lg:w-[50rem] w-full gap-10">
            <div className="flex flex-col gap-2">
              <label htmlFor="" className="font-semibold text-xl">
                Wallet number
              </label>
              <div className="font-light text-base flex gap-5 items-center">
                3101220589
                <span className="h-7 w-7 flex justify-center items-center rounded-full bg-[#F5CEF0] cursor-pointer">
                  <IoMdCopy size={16} color="#82006F" />
                </span>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="" className="font-semibold text-xl">
                Account status
              </label>
              <span className="font-light text-base text-colorGold">
                Not verified
              </span>
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="" className="font-semibold text-xl">
                NIN
              </label>
              <div className="border-[1px] rounded-lg p-3.5 flex justify-between gap-4 items-center border-borderColor">
                <input
                  type={showBalance ? "text" : "password"}
                  className="border-none focus:outline-0 bg-transparent placeholder:font-groteska-regular w-full placeholder:font-base text-white"
                />
                {showBalance ? (
                  <IoMdEye
                    onClick={() => setShowBalance(false)}
                    color="#fff"
                    className="cursor-pointer"
                    size={20}
                  />
                ) : (
                  <IoMdEyeOff
                    onClick={() => setShowBalance(true)}
                    color="#fff"
                    size={20}
                    className="cursor-pointer"
                  />
                )}
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="" className="font-semibold text-xl">
                BVN
              </label>
              <div className="border-[1px] rounded-lg p-3.5 flex justify-between gap-4 items-center border-borderColor">
                <input
                  type={showBalance ? "text" : "password"}
                  className="border-none focus:outline-0 bg-transparent placeholder:font-groteska-regular w-full placeholder:font-base text-white"
                />
                {showBalance ? (
                  <IoMdEye
                    onClick={() => setShowBalance(false)}
                    color="#fff"
                    className="cursor-pointer"
                    size={20}
                  />
                ) : (
                  <IoMdEyeOff
                    onClick={() => setShowBalance(true)}
                    color="#fff"
                    size={20}
                    className="cursor-pointer"
                  />
                )}
              </div>
            </div>
            <div className="">
              <Button as="btn">Verify Details</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Profile;
