import { createContext, useReducer, useContext } from "react";
import { Action, KeyValuePayload } from "../../model";

const initialState = {
  profile: {},
  token: "",
  isAuth: false
};
const UserContext = createContext<any>(null);

const UserReducer = (state: any, action: Action<KeyValuePayload>) => {
  switch (action.type) {
    case "set-property":
      return { ...state, [action.payload.key]: action.payload.value };
    case "clear-user-state":
      return { ...initialState };
  }
};

const UserProvider = (props: any) => {
  const [state, dispatch] = useReducer(UserReducer, { ...initialState });

  async function setUserProperty(key: string, value: any) {
    let val =
      typeof value === "string"
        ? value
        : typeof value === "number"
        ? String(value)
        : JSON.stringify(value);
    await dispatch({ type: "set-property", payload: { key, value } });
    localStorage.setItem(key, val);
  }

  async function recoverUserData() {
    for (let item of Object.keys(initialState)) {
      let retrievedData = await localStorage.getItem(item)!;
      retrievedData = ["number", "string"].includes(typeof state[item])
        ? retrievedData
        : !["null", "undefined"].includes(retrievedData)
        ? JSON.parse(retrievedData)
        : state[item];
      await setUserProperty(item, retrievedData);
    }
  }

  async function clearUserData() {
    await dispatch({
      type: "clear-user-state",
      payload: { key: "", value: "" },
    });
  }

  const actions = {
    setUserProperty,
    recoverUserData,
    clearUserData,
  };

  return (
    <UserContext.Provider value={{ user: state, ...actions }}>
      {props.children}
    </UserContext.Provider>
  );
};

function useUser() {
  const context = useContext(UserContext);
  if (context === undefined)
    throw new Error("UserContext was used outside the UserProvider");
  return context;
}
export { useUser, UserProvider };
