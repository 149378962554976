import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { UserProvider } from "./provider/contexts/userContext";
import { AppInfoProvider } from "./provider/contexts/appInfoContext";
import { VisibilityProvider } from "./provider/contexts/visibilityContext";
import { ApiProvider } from "./provider/API/call-services";
import { useAppInfo } from "./provider/contexts/appInfoContext";
import NotifierComponents from "react-pop-notifier";

const { NotifierProvider} = NotifierComponents;
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <NotifierProvider>
      <VisibilityProvider>
        <UserProvider>
          <AppInfoProvider>
            <ApiProvider>
              <App />
            </ApiProvider>
          </AppInfoProvider>
        </UserProvider>
      </VisibilityProvider>
    </NotifierProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
